.Options{
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-evenly;
    padding: 15px;
}

.caption{
    color: white;
    font-style: italic;
    padding: 10px;
}

@media(max-width: 600px){
    .Options{
        padding: 20px 0px 20px 0px;
    }
}