.caption{
    color:white;
}

.options{
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-evenly;
    padding: 26px;
}

@media(max-width: 600px){
    .caption{
        font-size: 1.5rem;
    }
}