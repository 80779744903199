.Wrapper{
    margin:0px;
    min-height: 100vh;
    background-color: #161b22;
}

.heading{
    margin: 0px;
    padding: 10px;
    color: white;
    position: relative;
    z-index: 2;
}

.heading:hover{
    text-decoration: none;
}

.mainContent{
    width: 40%;
    padding: 20px;
    margin: 5vh 0 0 50%;
    transform: translate(-50%, 0);
    border: 1px solid ;
    box-shadow: 1px 1px 10px;
    border-radius: 5%;
    display: grid;
    align-items: center;
    background-color: #0d1117;
    position: relative;
    z-index: 2;
}

@media(max-width: 800px){
    .mainContent{
        width: 50%;
    }
}

@media(max-width: 600px){
    .mainContent{
        width: 80%;
        transform: translate(-50%, -15%);
    }
    .heading{
        font-size: 2.2rem;
        transform: translate(0, -15%);
    }
}
