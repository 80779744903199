.row{
    display: grid;
    grid-template-columns: 50px 50px 50px;
    justify-content: center;
}

.result{
    color: white;
    margin: 0.7rem;
}

.board{
    margin: 0.7rem;
}